import React from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  Description,
  HorizontalDivider,
  RecruitmentWrapper,
  StageImageWrapper,
  StageWrapper,
  StageNumberWrapper,
  DescriptionItem,
  StageNumber,
  ArrowWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import ArrowBottom from "-!svg-react-loader!../../../../../../assets/images/arrowDown.svg";
import RecruitmentFirstStageIcon from "-!svg-react-loader!../../../../../../assets/images/recruitment/recruitment1.svg";
import RecruitmentSecondStageIcon from "-!svg-react-loader!../../../../../../assets/images/recruitment/recruitment2.svg";
import RecruitmentThirdStageIcon from "-!svg-react-loader!../../../../../../assets/images/recruitment/recruitment3.svg";
import RecruitmentFourthStageIcon from "-!svg-react-loader!../../../../../../assets/images/recruitment/recruitment4.svg";
import { PageTitle } from "../../../../../common/PageTitle";

interface RecruitmentProps {
  pageChange: PageChange;
}

const RecruitmentMobile: React.FC<RecruitmentProps> = ({ pageChange }) => {
  const { t } = useTranslation("career");

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`recruitment_title`))} />
      <PageWrapper animation={pageChange}>
        <Title>{fixConjunctions(t(`recruitment_title`))}</Title>
        <Description>
          {fixConjunctions(t(`recruitment_description`))}
        </Description>
        <HorizontalDivider />

        <RecruitmentWrapper>
          <StageWrapper>
            <StageNumberWrapper>
              <StageNumber>01</StageNumber>
              <StageImageWrapper>
                <RecruitmentFirstStageIcon width="68px" height="64px" />
              </StageImageWrapper>
            </StageNumberWrapper>
            <DescriptionItem
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`recruitment_firstStep`)),
              }}
            />
          </StageWrapper>
          <ArrowWrapper>
            <ArrowBottom height="54px" />
          </ArrowWrapper>
          <StageWrapper>
            <StageNumberWrapper>
              <StageNumber>02</StageNumber>
              <StageImageWrapper>
                <RecruitmentSecondStageIcon width="68px" height="64px" />
              </StageImageWrapper>
            </StageNumberWrapper>

            {((t(`recruitment_secondStep`) as unknown) as string[]).map(e => (
              <DescriptionItem>&middot; {e}</DescriptionItem>
            ))}
          </StageWrapper>
          <ArrowWrapper>
            <ArrowBottom height="54px" />
          </ArrowWrapper>
          <StageWrapper>
            <StageNumberWrapper>
              <StageNumber>03</StageNumber>
              <StageImageWrapper>
                <RecruitmentThirdStageIcon width="68px" height="64px" />
              </StageImageWrapper>
            </StageNumberWrapper>

            <DescriptionItem
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`recruitment_thirdStep`)),
              }}
            />
          </StageWrapper>
        </RecruitmentWrapper>
      </PageWrapper>
    </>
  );
};

export default RecruitmentMobile;
