import styled, { css } from "styled-components";

import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import {
  H2_REGULAR,
  H4,
  TEXT_BODY_1,
  TEXT_BUTTON_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-x: hidden;
  background: #151515;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const Title = styled.h1`
  ${H4}
  color: #ffffff;
  margin: 160px 24px 16px 24px;
`;

export const Description = styled.div`
  ${TEXT_BODY_1}
  color: #ffffff;
  margin: 0 24px 48px 24px;
`;

export const HorizontalDivider = styled.hr`
  width: 96px;
  height: 8px;
  background: #ffffff;
  margin: 0 0 80px 24px;
`;

export const DescriptionItem = styled.div`
  ${TEXT_BUTTON_2}
  color: #ffffff;
  width: 70%;
`;

export const RecruitmentWrapper = styled.div`
  padding: 0 24px 88px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #151515;
`;

export const StageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StageNumberWrapper = styled.div`
  display: flex;
`;

export const StageImageWrapper = styled.div`
  svg * {
    fill: #ffffff;
  }
`;

export const StageNumber = styled.div`
  ${H2_REGULAR}
  width: 100%;
  margin: 0;
  padding-bottom: 8px;
  color: #ffffff;
`;

export const ArrowWrapper = styled.div`
  margin: 40px 23px 93px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;

  svg > * {
    fill: #ffffff;
  }
`;
